export const OTP_API_URL = 'https://user.dst.samagra.io';
export const HTTP_BASIC_USER = 'admin';
export const HTTP_BASIC_PASS = 'qaz@123plm';


export const IS_UNDER_MAINTENANCE = false;
export const API_KEY = '6DgFjRZOE94Wd9tIERk79YWJkWjCqvf5JUyKxIuxUgs';
export const HASURA_QUERY_URL = 'https://hasura.dst.samagra.io/v1/graphql';
export const API_BASE_URL = 'https://user.dst.samagra.io/api';
export const APPLICATION_ID = '8924d6c5-de33-4b46-99e2-9d7502f72936';
